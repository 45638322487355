<template>
  <form
    class="form-cms"
    @submit.prevent="addRecipeStep"
    :class="[addStepShowMsg ? 'mb-1' : '']"
  >
    <div class="fields-flex mt-1">
      <div class="form-control">
        <label for="order-step-recipe">Orden:</label>
        <input
          type="number"
          name="order-recipe"
          placeholder="Orden"
          v-model="addStep.orden"
          required
        />
      </div>
      <div class="form-control">
        <label for="title-step-recipe">Título:</label>
        <input
          type="text"
          name="title-step-recipe"
          placeholder="Título"
          v-model="addStep.titulo"
          required
        />
      </div>
    </div>
    <div class="form-control">
      <label for="description-step-recipe">Descripción:</label>

      <textarea
        rows="5"
        name="description-step-recipe"
        placeholder="Descripción"
        v-model="addStep.descripcion"
        required
      />
    </div>
    <Message
      v-if="addStepShowMsg"
      :message="addStepMsg"
      :messageType="addStepMsgStatus"
    />
    <div class="buttons mt-1">
      <button type="submit" class="btn btn-primary" :disabled="isLoadingAdd">
        <template v-if="!isLoadingAdd">
          <i class="fas fa-plus"></i> Agregar
        </template>
        <template v-else>Agregando ...</template>
      </button>
    </div>
  </form>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useRecipeSteps from '@/modules/cms/composables/recipes/useRecipeSteps';

export default {
  components: {
    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },
  setup() {
    const {
      isLoadingAdd,
      recipeOrderSteps,
      addRecipeStep,
      addStep,
      addStepShowMsg,
      addStepMsg,
      addStepMsgStatus,
    } = useRecipeSteps();

    return {
      isLoadingAdd,
      recipeOrderSteps,
      addStepShowMsg,
      addStepMsg,
      addStepMsgStatus,
      addRecipeStep,
      addStep,
    };
  },
};
</script>
